/**
 * Sanitize HTML content to prevent XSS attacks
 * Use this function whenever rendering user-generated content
 */
export function sanitizeHtml(html: string, browser: boolean): string {
	if (!html) return ''

	if (typeof window !== 'undefined' && browser) {
		import('dompurify')
			?.then((DOMPurify) =>
				DOMPurify.sanitize(html, {
					ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'ul', 'ol', 'li'],
					ALLOWED_ATTR: ['href', 'target', 'rel'],
				}),
			)
			.then((res) => {
				return res
			})
	}

	return html.replace(/<[^>]*>?/gm, '')
}

export function sanitizeAllTagsHtml(html: string, browser: boolean): string {
	if (!html) return ''

	if (typeof window !== 'undefined' && browser) {
		import('dompurify')
			?.then((DOMPurify) =>
				DOMPurify.sanitize(html, {
					ALLOWED_TAGS: [],
					ALLOWED_ATTR: [],
					KEEP_CONTENT: true,
				}),
			)
			.then((res) => {
				return res
			})
	}

	return html.replace(/<[^>]*>?/gm, '')
}
